import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router/index';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: null,
    user: null,
    login_time: null
  },

  getters: {
    getUserToken(state) {
      return state.token;
    },
    isAuthenticated(state){
      return state.token && state.user;
    }
  },

  mutations: {
    loginUser(state, {user, token}){
      state.token = token;
      state.user = user;
      state.login_time = new Date().getTime();
      router.push('/dashboard');
    },
    logoutUser(state){
      state.token = null;
      state.user = null;
    }
  },
  actions: {
  },
  modules: {
  }
});