<template>
  <div class="home mt-5">
    <h3 class="title"> Bem Vindo</h3>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'Dashboard',
}
</script>
