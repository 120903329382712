export default [
  {
    path: '/galleries',
    name: 'Galleries Listing',
    component: () => import(/* webpackChunkName: "gallery-listing" */ '../components/galleries/GalleriesListing.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/galleries/:id',
    name: 'Gallery Edit',
    component: () =>
      import(/* webpackChunkName: "gallery-edit" */ '../components/galleries/GalleryEdit.vue'),
    meta: {
      requiresAuth: true
    },

  }
];