import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

import Dashboard from '../views/Dashboard.vue'

import galleryRoutes from "./galleryRoutes";

Vue.use(VueRouter)

var routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login-page" */ '../views/Login.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "login-page" */ '../views/Logout.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    },
  },
  ...galleryRoutes
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
});

/*router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.requiresAuth)) {
    /*if (store.getters.isAuthenticated) {
      next("/posts");
      return;
    }
    next();
  } else {
    next();
  }
}); */

export default router;