import BootstrapVue from 'bootstrap-vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import  VueToast  from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

require('bootstrap');
import "bootstrap/dist/css/bootstrap.min.css"

import '@fortawesome/fontawesome-free/css/all.min.css';

import vSelect from 'vue-select';
import VueSimpleAlert from "vue-simple-alert";
import VueLazyload from 'vue-lazyload'

import 'vue-select/dist/vue-select.css';
import './assets/scss/styles.scss';

Vue.use(VueSimpleAlert, { reverseButtons: true });
Vue.component('v-select', vSelect)
Vue.use(BootstrapVue);

// or with options
// const loadimage = require('./assets/loading.gif')
// const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 2
});

Vue.use(VueToast,
  {
    position: 'top',
    duration: 4500
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
